import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import React, { useEffect } from 'react'

interface IDebounceSearchSelect {
  mode: 'single' | 'multiple'
  placeholder: string
  fetchOptions: (
    props: Record<string, any> | string | string[] | any
  ) => Promise<any>
  setChangeFilter: (
    props: Record<string, any> | string | string[] | any
  ) => void
  isSelectAll?: boolean
  value?: any
}

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  isSelectAll,
  ...props
}: any) {
  const [fetching, setFetching] = React.useState(false)
  const [options, setOptions] = React.useState([])

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      setOptions([])
      setFetching(true)
      try {
        const dataResponse = await fetchOptions(value)
        setOptions(dataResponse.data)
        setFetching(false)
      } catch (error) {
        setOptions([])
        setFetching(false)
      }
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  useEffect(() => {
    debounceFetcher('')
  }, [])

  return (
    <Select
      // labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {isSelectAll ? (
        <Select.Option value={'all'}>Chọn tất cả</Select.Option>
      ) : null}
      {options.map((option: string | Record<string, any>, index: number) => {
        return (
          <Select.Option
            key={index}
            value={typeof option === 'string' ? option : option?.id}
          >
            {typeof option === 'string' ? option : option?.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

const DebounceSearchSelect = (props: IDebounceSearchSelect) => {
  const {
    placeholder,
    fetchOptions,
    setChangeFilter,
    mode,
    isSelectAll,
    value,
  } = props

  // xử lý tránh các lỗi chỗ khác
  const valueOption = value
    ? {
        value: value || [],
      }
    : {}

  return (
    <DebounceSelect
      mode={mode}
      showSearch
      placeholder={placeholder}
      fetchOptions={fetchOptions}
      onChange={(values: any) => {
        if (mode === 'multiple') {
          setChangeFilter(values)
        } else {
          setChangeFilter(values)
        }
      }}
      style={{
        width: '100%',
      }}
      isSelectAll={isSelectAll}
      {...valueOption}
    />
  )
}

export default DebounceSearchSelect
