import axios from 'axios'
import { ENVIRONMENT, SESSION_KEY } from 'common/config'
import Cookies from 'js-cookie'
import { ApiClient } from 'services/ApiService'

const google = 'google/'

export const getListGoogleCustomer = (payload: Record<string, any>) => {
  return ApiClient.get(
    `${google}listCustomer?query=${payload.query.replaceAll('&', '%26')}&page=${
      payload.page
    }`
  )
}

export const addAllListGoogleToGroup = (
  payload: Record<string, string | number>
) => {
  return ApiClient.post(`${google}transferCustomer`, payload)
}

export const filterPhone = (phone: string) => {
  return ApiClient.get(`${google}filterPhone?search=${phone}`)
}

export const filterProvince = (province: string) => {
  return ApiClient.get(`${google}filterProvince?search=${province}`)
}

export const filterProfession = (profession: string) => {
  return ApiClient.get(`${google}filterProfession?search=${profession}`)
}

export const filterWebsite = (website: string) => {
  return ApiClient.get(`${google}filterWebsite?search=${website}`)
}

export const filterName = (name: string) => {
  return ApiClient.get(`${google}filterName?search=${name}`)
}

const BASE_URL_DEV = ENVIRONMENT.BASE_URL

//api - export excel file - limit customer
export const handleExportExcelGoogleMap = (
  payload: Record<string, any>,
  params?: any
) => {
  const data = axios.get(
    `${BASE_URL_DEV}${google}exportCustomer?query=${payload.query.replaceAll(
      '&',
      '%26'
    )}&page=${payload.page}`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': null,
        token: Cookies.get(SESSION_KEY.SESSION),
      },
      params,
    }
  )
  return data
}

//api - export excel file - full customer
// export const handleExportExcelGoogleMap = (payload: Record<string, any>) => {
//   return ApiClient.get(
//     `${google}exportCustomerUseBull?query=${payload.query.replaceAll(
//       '&',
//       '%26'
//     )}&page=${payload.page}`
//   )
// }
