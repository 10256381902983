import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Input, Row, Select } from 'antd'
import DebounceSearchSelect from 'common/components/DebounceSearchSelect'
import moment from 'moment'
import React, { FC } from 'react'
import provinces from 'utils/provinces'
import {
  getLinhVucKinhTe,
  getNghanhNgheChinh,
} from '../BusinessRegistrationAPI'
import DebounceSearchSelectAll from 'common/components/DebounceSearchSelect/DebounceSearchSelectAll'
import { filterProvince } from 'features/customer-resources/google/GoogleApi'

const { RangePicker } = DatePicker
const { Option } = Select

interface FilterProps {
  filterList: Record<string, any>
  onChangeData: Record<string, React.Dispatch<React.SetStateAction<any>>>
  setShowData: any
  values?: any
}

const Filter: FC<FilterProps> = props => {
  const handleChangeDatetime = (dates: any) => {
    props.onChangeData.setDate({
      from: moment(dates[0]).format('YYYY-MM-DD'),
      to: moment(dates[1]).format('YYYY-MM-DD'),
    })
  }

  const handleChangeNganhNgheChinh = (value: string[]) => {
    const currents = props.values.pickedNganhnghechinh

    const isAll = currents.includes('all')

    let values: any = []
    if (value.includes('all')) {
      if (value.length > 1 && isAll) {
        values = value.filter(item => item !== 'all')
      } else {
        values = ['all']
      }
    } else {
      values = value
    }
    props.onChangeData.setPage(1)
    props.onChangeData.setPickedNganhnghechinh(values)
  }

  const handleChangeLoaiKhoan = (values: string[]) => {
    props.onChangeData.setPage(1)
    props.onChangeData.setPickedLoaikhoan(values)
  }

  const handleChangeLinhVucKinhTe = (values: string[]) => {
    props.onChangeData.setPage(1)
    props.onChangeData.setPickedlinhVucKinhTe(values)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Input.Search
          enterButton={<SearchOutlined />}
          size="middle"
          placeholder="Nhập tên doanh nghiệp"
          onChange={e => {
            props.onChangeData.setPage(1)
            props.onChangeData.setSearchQuery(e.target.value)
          }}
        />
      </Col>
      <Col span={6}>
        <Input.Search
          enterButton={<SearchOutlined />}
          placeholder="Nhập mã số thuế"
          onChange={e => {
            props.onChangeData.setPage(1)
            props.onChangeData.setMst(e.target.value)
          }}
        />
      </Col>
      <Col span={6}>
        {/* <Select
          showSearch
          mode="multiple"
          placeholder="Tỉnh/thành phố"
          optionFilterProp="children"
          style={{ width: '100%' }}
          maxTagCount={1}
          allowClear
          onChange={(e: string) => {
            props.onChangeData.setPage(1)
            props.onChangeData.setProvince(e)
          }}
        >
          {provinces.map((province: any, index: number) => (
            <Option key={index} value={province.name}>
              {province.name}
            </Option>
          ))}
        </Select> */}
        <DebounceSearchSelectAll
          mode="multiple"
          fetchOptions={filterProvince}
          setChangeFilter={(e: string) => {
            props.onChangeData.setPage(1)
            props.onChangeData.setProvince(e)
          }}
          placeholder="Nhập tỉnh thành"
        />
      </Col>
      <Col span={6}>
        <RangePicker
          allowClear={false}
          style={{ width: '100%' }}
          defaultValue={[
            moment(props.filterList.datePicker.from),
            moment(props.filterList.datePicker.to),
          ]}
          format="DD/MM/YYYY"
          onChange={e => {
            props.onChangeData.setPage(1)
            handleChangeDatetime(e)
          }}
        />
      </Col>
      <Col span={6}>
        <DebounceSearchSelect
          mode="multiple"
          setChangeFilter={handleChangeNganhNgheChinh}
          fetchOptions={getNghanhNgheChinh}
          placeholder="Nhập ngành nghề chính"
          isSelectAll
          value={props.values.pickedNganhnghechinh}
        />
      </Col>
      {/* <Col span={6}>
        <DebounceSearchSelect
          mode="multiple"
          setChangeFilter={handleChangeLoaiKhoan}
          fetchOptions={getLoaiKhoan}
          placeholder="Nhập loại khoán"
        />
      </Col> */}
      <Col span={6}>
        <DebounceSearchSelectAll
          mode="multiple"
          setChangeFilter={handleChangeLinhVucKinhTe}
          fetchOptions={getLinhVucKinhTe}
          placeholder="Nhập lĩnh vực kinh tế"
        />
      </Col>
      <Col span={6}>
        <Select
          style={{ width: '100%' }}
          onChange={e => {
            props.onChangeData.setPage(1)
            props.onChangeData.setIsActive(e)
          }}
          defaultValue={1}
          maxTagCount={1}
          placeholder="Chọn trạng thái!"
          allowClear={true}
        >
          <Select.Option value={1}>Đang hoạt động</Select.Option>
          <Select.Option value={0}>Không hoạt động</Select.Option>
        </Select>
      </Col>
      <Col span={6}>
        <Button type="primary" onClick={() => props.setShowData(true)}>
          Tìm kiếm
        </Button>
      </Col>
    </Row>
  )
}
export default Filter
