import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import React, { useEffect, useState } from 'react'

interface IDebounceSearchSelect {
  mode: 'single' | 'multiple'
  placeholder: string
  fetchOptions: (
    props: Record<string, any> | string | string[] | any
  ) => Promise<any>
  setChangeFilter: (
    props: Record<string, any> | string | string[] | any
  ) => void
}

function DebounceSelectAll({
  fetchOptions,
  debounceTimeout = 800,
  mode,
  ...props
}: any) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedValues, setSelectedValues] = useState<any>([])

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      setOptions([])
      setFetching(true)
      try {
        const dataResponse = await fetchOptions(value)
        setOptions(dataResponse.data)
        setFetching(false)
      } catch (error) {
        setOptions([])
        setFetching(false)
      }
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  useEffect(() => {
    debounceFetcher('')
  }, [])

  const handleSelectAll = (selectAll: boolean) => {
    if (selectAll) {
      const allValues = options.map((option: any) => ({
        label: typeof option === 'string' ? option : option?.name,
        value: typeof option === 'string' ? option : option?.id,
      }))
      setSelectedValues(allValues)
      props.onChange(allValues)
    } else {
      setSelectedValues([])
      props.onChange([])
    }
  }

  const handleChange = (values: any) => {
    const selectAllOptionIncluded = values.some(
      (v: any) => v.value === 'select-all'
    )

    if (selectAllOptionIncluded) {
      if (selectedValues.length !== options.length) {
        handleSelectAll(true) // Select all options
      } else {
        handleSelectAll(false) // Deselect all options
      }
    } else {
      // Normal selection/deselection
      const filteredValues = values.filter((v: any) => v.value !== 'select-all')
      setSelectedValues(filteredValues)
      props.onChange(filteredValues)
    }
  }

  const selectAllOption = {
    label:
      selectedValues.length === options.length && options.length > 0
        ? 'Bỏ chọn tất cả'
        : 'Chọn tất cả',
    value: 'select-all',
  }

  const mergedOptions = [
    selectAllOption,
    ...options.map((option: any) => ({
      label: typeof option === 'string' ? option : option?.name,
      value: typeof option === 'string' ? option : option?.id,
    })),
  ]

  return (
    <Select
      allowClear
      mode={mode}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      value={selectedValues}
      onChange={handleChange}
      options={mergedOptions}
      maxTagCount="responsive"
    />
  )
}

const DebounceSearchSelectAll = (props: IDebounceSearchSelect) => {
  const { placeholder, fetchOptions, setChangeFilter, mode } = props

  return (
    <DebounceSelectAll
      mode={mode}
      showSearch
      placeholder={placeholder}
      fetchOptions={fetchOptions}
      onChange={(values: any) => {
        if (mode === 'multiple') {
          setChangeFilter(
            values
              .filter((v: any) => v.value !== 'select-all')
              .map((value: any) => value.value)
          )
        } else {
          setChangeFilter(values.value)
        }
      }}
      style={{
        width: '100%',
      }}
    />
  )
}

export default DebounceSearchSelectAll
