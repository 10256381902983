import { Button, Input, InputNumber, Popover } from 'antd'
import R from 'assets'
import React from 'react'
import { SELECT_MEMBER_TYPE } from 'utils/constants'
import { exportComments, exportMembers } from '../FacebookApi'

const ExportFromToPage = ({
  valueSelect,
  id,
  totalrecord,
  isOnlyChooseCustomerWithPhone,
  selectedRowListID,
}: any) => {
  const [pageExport, setPageExport] = React.useState({
    from_page: 1,
    to_page: 2,
  })

  const totalPage = totalrecord ? Math.ceil(totalrecord / 12) : 0
  const caculateRecordFromToPage =
    (pageExport.to_page - pageExport.from_page + 1) * 12

  //export excel file - limit customer
  const [
    isLoadingButtonExportExcel,
    setIsLoadingButtonExportExcel,
  ] = React.useState<boolean>(false)

  const exportExcel = async (isPaging: any) => {
    setIsLoadingButtonExportExcel(true)

    const payload = {
      group_id: id,
      phone: isOnlyChooseCustomerWithPhone ? 1 : 0,
      ids: isPaging
        ? ''
        : selectedRowListID.map((row: { id: any }) => row.id).join(','),
    }
    try {
      if (valueSelect === SELECT_MEMBER_TYPE.CUSTOMER) {
        const res = await exportMembers(payload, {
          from_page: pageExport.from_page || undefined,
          to_page: pageExport.to_page || undefined,
        })
        setIsLoadingButtonExportExcel(false)

        window.open(res.data.file, '_blank')
      }
      if (valueSelect === SELECT_MEMBER_TYPE.POST) {
        const res = await exportComments(payload, {
          from_page: pageExport.from_page || undefined,
          to_page: pageExport.to_page || undefined,
        })
        setIsLoadingButtonExportExcel(false)

        window.open(res.data.file, '_blank')
      }
    } catch (err) {
      setIsLoadingButtonExportExcel(false)

      console.log('err', err)
    }
  }

  return totalrecord > 0 ? (
    <Popover
      trigger="click"
      title="Xuất excel theo phân trang"
      content={
        <div>
          <Input.Group compact>
            <InputNumber
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              onChange={from_page => {
                setPageExport({
                  ...pageExport,
                  from_page,
                })
              }}
              value={pageExport.from_page}
              step={1}
              min={1}
              style={{ width: 100, textAlign: 'center' }}
              placeholder="Từ trang"
              type="number"
            />
            <Input
              className="site-input-split"
              style={{
                width: 30,
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: 'none',
              }}
              placeholder="~"
              disabled
            />
            <InputNumber
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={pageExport.to_page}
              onChange={to_page => {
                setPageExport({
                  ...pageExport,
                  to_page,
                })
              }}
              max={totalPage}
              step={1}
              style={{
                width: 100,
                textAlign: 'center',
              }}
              placeholder="Đến trang"
              type="number"
            />
          </Input.Group>
          <div
            style={{
              color: 'red',
              fontSize: '12px',
              fontStyle: 'italic',
              margin: '5px 0',
            }}
          >
            Export tối đa 2000 bản ghi
          </div>
          <Button
            loading={isLoadingButtonExportExcel}
            onClick={() => exportExcel(true)}
            style={{ width: '100%' }}
            type="primary"
          >
            Export {caculateRecordFromToPage && `(${caculateRecordFromToPage})`}
          </Button>
        </div>
      }
    >
      <Button
        type="primary"
        style={{
          marginLeft: '5px',
          backgroundColor: 'green',
          border: 'none',
          color: 'white',
        }}
        disabled={isLoadingButtonExportExcel}
        // onClick={exportExcel}
      >
        {R.strings().action_export_excel_file} phân trang
      </Button>
    </Popover>
  ) : null
}

export default ExportFromToPage
