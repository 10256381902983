import { PlusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  message,
  PageHeader,
  Pagination,
  Row,
  Spin,
} from 'antd'
import R from 'assets'
import { Wrapper } from 'common/components/Screens'
import SearchToShowData from 'common/components/SearchToShowData/SearchToShowData'
import { TableAntdStyle } from 'features/styles/Styles'
import { saveAs } from 'file-saver'
import * as _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import AddAllCustomerToGroupModal from '../components/AddAllCustomerToGroupModal'
import AddCustomerToGroupModal from '../components/AddCustomerToGroupModal'
import {
  getLinhVucKinhTe,
  getListDKKD,
  getLoaiKhoan,
  getNghanhNgheChinh,
  handleExportExcelDKKD,
} from './BusinessRegistrationAPI'
import Filter from './components/Filter'
import ExportFromToPage from './components/ExportFromToPage'

export default function BusinessRegistration() {
  const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])
  const [
    showAddCustomerToGroupModal,
    setShowAddCustomerToGroupModal,
  ] = useState<boolean>(false)
  const [
    showAddAllCustomerToGroupModal,
    setShowAddAllCustomerToGroupModal,
  ] = useState<boolean>(false)

  const [totalrecord, setTotalrecord] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [province, setProvince] = useState<string[]>([])
  const [mst, setMst] = useState<string>('')
  const [dataSource, setDataSource] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Record<string, string>>({
    from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })
  const [isActive, setIsActive] = useState<number>(1)
  const limit = 12

  const [nganhnghechinh, setNganhnghechinh] = useState<any[]>([])
  const [loaikhoan, setLoaikhoan] = useState<any[]>([])
  const [linhvuckinhte, setLinhvuckinhte] = useState<any[]>([])
  const [filter, setFilter] = useState<Record<string, any>>({
    nganhnghechinh: '',
    loaikhoan: '',
    linhvuckinhte: '',
  })
  const [showData, setShowData] = useState<boolean>(false)

  const columns = [
    {
      title: 'Mã số thuế',
      dataIndex: 'MST',
      key: 'MST',
    },
    {
      title: 'Doanh nghiệp',
      dataIndex: 'TEN_DOANH_NGHIEP',
      key: 'TEN_DOANH_NGHIEP',
    },
    {
      title: 'DKKD ID',
      dataIndex: 'SDT',
      key: 'SDT',
      render: (data: string) => {
        try {
          return <div>{data.slice(0, -3) + '***'}</div>
        } catch (error) {
          return <div>{data}</div>
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'EMAIL',
      key: 'EMAIL',
    },
    {
      title: 'Ngành nghề chính',
      dataIndex: 'TEN_NGANH_NGHE_CHINH',
      key: 'TEN_NGANH_NGHE_CHINH',
    },
    {
      title: 'Tỉnh/thành phố',
      dataIndex: 'TEN_TINH_THANH_PHO',
      key: 'TEN_TINH_THANH_PHO',
    },
    {
      title: 'Lĩnh vực kinh tế',
      dataIndex: 'LINH_VUC_KINH_TE',
      key: 'LINH_VUC_KINH_TE',
    },
    {
      title: 'Nội dung trạng thái',
      dataIndex: 'NOI_DUNG_TRANG_THAI',
      key: 'NOI_DUNG_TRANG_THAI',
    },
    // {
    //   title: 'Loại khoán',
    //   dataIndex: 'LOAI_KHOAN',
    //   key: 'LOAI_KHOAN',
    // },
    {
      title: 'Địa chỉ',
      dataIndex: 'DIA_CHI',
      key: 'DIA_CHI',
    },
    // {
    //   title: 'Ngày thành lập',
    //   dataIndex: 'NGAY_TAO',
    //   key: 'NGAY_TAO',
    //   render: (NGAY_TAO: string) => moment(NGAY_TAO).format('DD/MM/YYYY'),
    // },
  ]
  const [pickedNganhnghechinh, setPickedNganhnghechinh] = useState<any[]>([])
  const [pickedLoaikhoan, setPickedLoaikhoan] = useState<any[]>([])
  const [pickedlinhVucKinhTe, setPickedlinhVucKinhTe] = useState<any[]>([])
  const [phone, setPhone] = useState<boolean>(true)
  const [payloadConvertAllCustomer, setPayloadConvertAllCustomer] = useState<
    Record<string, any>
  >({})

  const rowSelection = {
    selectedRowKeys: selectedRowList.map(item => item.id),
    onSelectAll: (
      selected: boolean,
      selectedRows: any[],
      changeRows: any[]
    ) => {
      changeRows = changeRows.map((row: any) => ({
        id: row.ID,
        name: row.TEN_DOANH_NGHIEP,
        phone: row.SDT,
        email: row.EMAIL,
      }))

      if (!selected) {
        setSelectedRowList((prev: any) =>
          prev.filter(
            (row: any) => !changeRows.some(item => row.id === item.id)
          )
        )
      } else {
        const newChoseRow = [...selectedRowList]
        changeRows.forEach(row => {
          if (!newChoseRow.some(item => item.id === row.id)) {
            newChoseRow.push(row)
          }
        })
        setSelectedRowList(newChoseRow)
      }
    },
    onSelect: (record: any, selected: any) => {
      if (!selected) {
        setSelectedRowList((prev: any) =>
          prev.filter((row: any) => row.id !== record.ID)
        )
      } else {
        setSelectedRowList((prev: any) => [
          ...prev,
          {
            id: record.ID,
            name: record.TEN_DOANH_NGHIEP,
            phone: record.SDT,
            email: record.EMAIL,
          },
        ])
      }
    },
  }
  const initDKKD = async (payload: any) => {
    setLoading(true)
    try {
      const initDKKDResponse = await getListDKKD(payload)
      const addedKeyListDKKD = initDKKDResponse.data.map(
        (customer: any, index: number) => ({
          ...customer,
          key: customer.ID,
        })
      )
      setDataSource(addedKeyListDKKD)
      setTotalrecord(initDKKDResponse.paging.totalItemCount)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoading(false)
    }
  }

  const handleConvertAll = () => {
    const sellect_all_enterprise_type = pickedNganhnghechinh.includes('all')
      ? {
          sellect_all_enterprise_type: 1,
        }
      : {}

    setPayloadConvertAllCustomer({
      search: searchQuery,
      fromDate: date.from,
      toDate: date.to,
      query: JSON.stringify({
        // province_name: province,
        // enterprise_type: pickedLoaikhoan,
        // loai_khoan: pickedNganhnghechinh,
        // linh_vuc: pickedlinhVucKinhTe,

        province_name: province,
        enterprise_type: pickedNganhnghechinh.includes('all')
          ? []
          : pickedNganhnghechinh,
        ...sellect_all_enterprise_type,
        loai_khoan: pickedLoaikhoan,
        linh_vuc: pickedlinhVucKinhTe,
      }),
      is_active: isActive,
      mst: mst,
    })
    setShowAddAllCustomerToGroupModal(true)
  }

  const getDanhSachNghanhNgheChinh = async (search: string) => {
    try {
      const response = await getNghanhNgheChinh(search)
      setNganhnghechinh(response.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getDanhSachLoaiKhoan = async (search: string) => {
    try {
      const response = await getLoaiKhoan(search)
      setLoaikhoan(response.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getDanhSachLinhVucKinhTe = async (search: string) => {
    try {
      const response = await getLinhVucKinhTe(search)
      setLinhvuckinhte(response.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const debounceGetSListDKKD = useCallback(
    _.debounce(payload => {
      initDKKD(payload)
    }, 500),
    []
  )
  const debounceGetDanhSachNghanhNgheChinh = useCallback(
    _.debounce(payload => {
      getDanhSachNghanhNgheChinh(payload)
    }, 500),
    []
  )
  const debounceLoaiKhoan = useCallback(
    _.debounce(payload => {
      getDanhSachLoaiKhoan(payload)
    }, 500),
    []
  )
  const debounceLinhVucKinhTe = useCallback(
    _.debounce(payload => {
      getDanhSachLinhVucKinhTe(payload)
    }, 500),
    []
  )

  useEffect(() => {
    debounceGetDanhSachNghanhNgheChinh(filter.nganhnghechinh)
  }, [filter.nganhnghechinh])

  useEffect(() => {
    debounceLinhVucKinhTe(filter.linhvuckinhte)
  }, [filter.linhvuckinhte])

  useEffect(() => {
    debounceLoaiKhoan(filter.loaikhoan)
  }, [filter.loaikhoan])

  useEffect(() => {
    if (showData) {
      const sellect_all_enterprise_type = pickedNganhnghechinh.includes('all')
        ? {
            sellect_all_enterprise_type: 1,
          }
        : {}

      const query = JSON.stringify({
        province_name: province,
        enterprise_type: pickedNganhnghechinh.includes('all')
          ? []
          : pickedNganhnghechinh,
        ...sellect_all_enterprise_type,
        loai_khoan: pickedLoaikhoan,
        linh_vuc: pickedlinhVucKinhTe,
      })

      debounceGetSListDKKD({
        page: page,
        search: searchQuery,
        mst: mst,
        date: date,
        query,
        isActive,
        phone,
      })
    }
  }, [
    date,
    debounceGetSListDKKD,
    isActive,
    mst,
    page,
    phone,
    pickedLoaikhoan,
    pickedNganhnghechinh,
    pickedlinhVucKinhTe,
    province,
    searchQuery,
    showData,
  ])

  // const handleSearchData = () => {
  //   const query = JSON.stringify({
  //     province_name: province,
  //     enterprise_type: pickedNganhnghechinh,
  //     loai_khoan: pickedLoaikhoan,
  //     linh_vuc: pickedlinhVucKinhTe,
  //   })

  //   debounceGetSListDKKD({
  //     page: page,
  //     search: searchQuery,
  //     mst: mst,
  //     date: date,
  //     query,
  //     isActive,
  //     phone,
  //   })
  // }

  //export excel file - full customer
  // const userState = useSelector(
  //   (redux: Record<string, any>) => redux.authReducer?.userInfo
  // )
  // const { exportExcel, isLoading } = useExportExcelFileWithSocket({
  //   callback: () => {
  //     const query = JSON.stringify({
  //       province_name: province,
  //       enterprise_type: pickedNganhnghechinh,
  //       loai_khoan: pickedLoaikhoan,
  //       linh_vuc: pickedlinhVucKinhTe,
  //     })
  //     const payload: any = {
  //       page: page,
  //       search: searchQuery,
  //       mst: mst,
  //       date: date,
  //       query,
  //       isActive,
  //       phone,
  //     }

  //     return handleExportExcelDKKD(payload)
  //   },
  //   channel: `export_excel_dkkd_${userState?.id}`,
  //   fileName: 'Du_lieu_DKKD',
  // })

  //export excel file - limit customer
  const [
    isLoadingButtonExportExcel,
    setIsLoadingButtonExportExcel,
  ] = useState<boolean>(false)
  const exportExcel = async () => {
    try {
      const sellect_all_enterprise_type = pickedNganhnghechinh.includes('all')
        ? {
            sellect_all_enterprise_type: 1,
          }
        : {}
      const query = JSON.stringify({
        province_name: province,
        enterprise_type: pickedNganhnghechinh.includes('all')
          ? []
          : pickedNganhnghechinh,
        ...sellect_all_enterprise_type,
        loai_khoan: pickedLoaikhoan,
        linh_vuc: pickedlinhVucKinhTe,
      })
      const payload: any = {
        page: page,
        search: searchQuery,
        mst: mst,
        date: date,
        query,
        isActive,
        phone,
      }
      const ids = selectedRowList?.map(item => item.id) || []

      setIsLoadingButtonExportExcel(true)
      message.success(
        R.strings().message_request_has_been_received +
          ' ' +
          R.strings().message_please_wait_a_few_minutes
      )
      const response = await handleExportExcelDKKD(payload, {
        ids: ids.toString() || undefined,
      })
      setSelectedRowList([])
      saveAs(response.data, `Du_lieu_DKKD.xlsx`)
    } catch (error) {
      console.log('Error - Export excel file - DKKD: ', { error })
      message.error(
        R.strings().message_an_error_occurred +
          ' ' +
          R.strings().message_please_try_again
      )
    } finally {
      setIsLoadingButtonExportExcel(false)
    }
  }

  return (
    <div>
      <Wrapper>
        <PageHeader
          className="site-page-header"
          title=" Tìm kiếm TTKD trên Google"
        />
      </Wrapper>

      <Wrapper>
        <div style={{ marginBottom: '10px' }}>
          <Filter
            filterList={{
              datePicker: date,
              phone: phone,
              nganhnghechinh: nganhnghechinh,
              loaikhoan: loaikhoan,
              linhvuckinhte: linhvuckinhte,
            }}
            values={{ pickedNganhnghechinh }}
            setShowData={setShowData}
            onChangeData={{
              setSearchQuery,
              setProvince,
              setMst,
              setDate,
              setPickedNganhnghechinh,
              setPickedLoaikhoan,
              setPickedlinhVucKinhTe,
              setFilter,
              setIsActive,
              setPage,
              setPhone,
            }}
          />
        </div>

        <div style={{ float: 'right', marginBottom: '10px' }}>
          <Checkbox checked={phone} onChange={() => setPhone(!phone)}>
            Lọc thông tin
          </Checkbox>
          {phone && (
            <Button
              type="primary"
              style={{ marginRight: '10px' }}
              onClick={() => handleConvertAll()}
            >
              {R.strings().action_select_all}
            </Button>
          )}

          <Button
            type="primary"
            onClick={() => setShowAddCustomerToGroupModal(true)}
          >
            <PlusCircleOutlined /> {R.strings().action_add_customer_group}
          </Button>

          <Button
            type="primary"
            style={{
              marginLeft: '5px',
              backgroundColor: 'green',
              border: 'none',
              color: 'white',
            }}
            disabled={isLoadingButtonExportExcel}
            onClick={exportExcel}
          >
            {R.strings().action_export_excel_file}
          </Button>

          <ExportFromToPage
            province={province}
            pickedNganhnghechinh={pickedNganhnghechinh}
            pickedLoaikhoan={pickedLoaikhoan}
            pickedlinhVucKinhTe={pickedlinhVucKinhTe}
            page={page}
            searchQuery={searchQuery}
            mst={mst}
            date={date}
            isActive={isActive}
            phone={phone}
            totalrecord={totalrecord}
          />
        </div>
        {loading && (
          <Spin
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              height: '200px',
              width: '100%',
            }}
            tip="Đang tìm kiếm trên Google"
          />
        )}
        <div>
          {dataSource.length && !loading ? (
            <TableAntdStyle
              scroll={{
                x: 1920,
                scrollToFirstRowOnChange: true,
                // y: 'calc(100vh - 370px)',
              }}
              rowSelection={{ ...rowSelection }}
              dataSource={dataSource}
              // loading={loading}
              pagination={false}
              columns={columns}
            />
          ) : (
            dataSource.length <= 0 &&
            !loading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '4px',
                  height: '300px',
                  width: '100%',
                }}
              >
                <SearchToShowData />
              </div>
            )
          )}
        </div>
        <Row justify="end">
          {totalrecord > 0 && (
            <Pagination
              showSizeChanger={false}
              style={{ marginTop: '10px' }}
              onChange={e => {
                setPage(e)
              }}
              current={page}
              pageSize={limit}
              total={totalrecord}
            />
          )}
        </Row>
      </Wrapper>

      {showAddCustomerToGroupModal && (
        <AddCustomerToGroupModal
          choosenConvertedFBList={selectedRowList}
          show={showAddCustomerToGroupModal}
          setShowAddCustomerToGroupModal={setShowAddCustomerToGroupModal}
        />
      )}

      {showAddAllCustomerToGroupModal && (
        <AddAllCustomerToGroupModal
          payload={payloadConvertAllCustomer}
          total={totalrecord}
          visible={showAddAllCustomerToGroupModal}
          from="DKKD"
          setVisible={setShowAddAllCustomerToGroupModal}
        />
      )}
    </div>
  )
}
